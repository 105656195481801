<!-- 新闻动态新增 -->
<template>
    <div class="AddNewsDynamic">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">新闻动态新增</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <!-- 表单 -->
        <el-form :model="form" :rules="rules" ref="form" class="form" label-width="100px">
            <el-form-item label="标题" prop="">
                <el-input v-model="form.title" placeholder="请输入标题"></el-input>
            </el-form-item>
            <el-form-item label="简介" prop="">
                <el-input v-model="form.introduce" placeholder="请输入简介"></el-input>
            </el-form-item>
            <el-form-item label="图片" prop="">
                <el-upload class="avatar-uploader"
                    action="https://artxt.szart.cn/api/public/index.php/api/common/upload" :show-file-list="false"
                    :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                    <img v-if="imageUrl!==null" :src="this.fullurl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
            </el-form-item>
            <el-form-item label="发布时间" prop="">
                <el-date-picker v-model="form.releaseTime" type="date" placeholder="选择时间" class="date">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="来源" prop="">
                <el-input v-model="form.source" placeholder="请输入来源"></el-input>
            </el-form-item>
            <el-form-item label="来源地址" prop="">
                <el-input v-model="form.sourceAddress" placeholder="请输入来源地址"></el-input>
            </el-form-item>
            <el-form-item label="内容" prop="">
                <!-- 富文本 -->
                <Editor :init="editorInit" v-model="form.content"></Editor>
            </el-form-item>
            <el-form-item label="排序" prop="">
                <el-input v-model="form.introduce" placeholder="请输入排序"></el-input>
            </el-form-item>
            <el-form-item label="状态" prop="">
                <div id="sex" style="width: 300px;">
                    <el-radio-group v-model="form.status">
                        <el-radio :label="1">显示</el-radio>
                        <el-radio :label="2">不显示</el-radio>
                    </el-radio-group>
                </div>
            </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <!-- 底部 提交与取消 -->
        <div slot="footer" class="dialog-footer">
            <el-button style="background-color: #18BC9C;color: #FFFFFF;" @click="submitForm('form')">提 交</el-button>
            <el-button class="btn" @click="resetForm('form')">取 消</el-button>
        </div>
    </div>
</template>

<script>
    import Editor from '@tinymce/tinymce-vue'
    export default {
        data() {
            return {
                form: {
                    title: "", //标题
                    introduce: "", //简介
                    releaseTime: "", //发布时间
                    source: "", //来源
                    sourceAddress: "", //来源地址
                    content: "", //内容
                    sorting: "", //排序
                    status: 1 //状态
                },
                rules: {

                },
                imageUrl: null,//接收图片上传的回调
                fullurl: "",//图片显示的路径
                url: "", //图片上传的路径
                //富文本配置
                editorInit: {
                    language: 'zh_CN',
                    // skin_url: "/skins/ui/oxide",
                    height: 500,
                    width: 700,
                    convert_urls: false,
                    plugins: [
                        'advlist anchor autolink autosave code codesample colorpicker colorpicker contextmenu directionality emoticons fullscreen hr image imagetools importcss insertdatetime link lists media nonbreaking noneditable pagebreak paste preview print save searchreplace spellchecker tabfocus table template textcolor textpattern visualblocks visualchars wordcount'
                    ],
                    toolbar: [
                        'searchreplace bold italic underline strikethrough alignleft aligncenter alignright outdent indent  blockquote undo redo removeformat subscript superscript code codesample',
                        'hr bullist numlist link image charmap preview anchor pagebreak insertdatetime media table emoticons forecolor backcolor fullscreen'
                    ],
                    images_upload_handler: (blobInfo, success, failure)=> {
                        const formData = new FormData();
                        formData.append('file', blobInfo.blob());
                        // console.log(blobInfo.blob())
                        
                        this.$request({
                            url: '/api/common/upload',
                            method: 'POST',
                            data: formData
                        }).then(res => {
                            // console.log(res)
                            success(res.data.fullurl);
                            
                        }).catch(() => {
                            
                            failure('上传失败，请重新上传');
                            
                        });
                    }
                },
            }
        },
        components: {
            Editor
        },
        created() {

        },
        methods: {
            goBack() { //返回
                this.$router.go(-1);
            },
            // 上传图片
            handleAvatarSuccess(res, file) {
                console.log(res, file)
                // this.imageUrl = res.data;
                // this.fullurl=this.imageUrl.fullurl
                // this.url=this.imageUrl.url
            },
            beforeAvatarUpload(file) {
                console.log(file.type)
                const isJPG = file.type === 'image/jpg';
                const isJPEG = file.type === 'image/jpeg';
                const isPNG = file.type === 'image/png';
                const isLt2M = file.size / 1024 / 1024 < 2;
                let flag = [isJPG, isJPEG, isPNG].includes(true)
                if (!flag) {
                    this.$message.error('上传图片只能是 JPG/JPEG/PNG 格式!');
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过2MB!');
                }

                return flag && isLt2M;
            },
            
            submitForm(form) { //提交
                this.$refs[form].validate((valid) => {
                    if (valid) {
                        // alert('submit!');
                        console.log(this.form)
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(form) { //取消
                this.$router.go(-1)
                // this.$refs[form].resetFields();
            },
        }
    }
</script>

<style scoped="scoped">
    .AddNewsDynamic {
        width: 100%;
        height: 100%;
    }

    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 20px 28px;
        display: flex;
        justify-content: space-between;
    }

    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }

    /* 表单 */
    .form {
        padding-top: 34px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }

    .form ::v-deep .el-input__inner {
        width: 600px;
        height: 37px;
    }

    .form ::v-deep .el-form-item__label {
        color: #000000;
    }

    .form ::v-deep .date .el-input__inner {
        width: 320px !important;
    }

    /* 性别选择按钮 */
    .form ::v-deep .el-radio__input.is-checked .el-radio__inner {
        background-color: #18BC9C;

    }

    .form ::v-deep.el-radio__input.is-checked+.el-radio__label {
        color: #18BC9C;
    }

    .form ::v-deep .el-radio__inner:hover {
        border-color: #18BC9C;
    }

    /* 图片 */
    ::v-deep .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 120px;
        height: 140px;
        line-height: 140px;
        text-align: center;
    }

    .avatar {
        width: 120px;
        height: 140px;
        display: block;
    }
    /* 底部提交 取消 */
    .dialog-footer {
        text-align: center;
        padding-bottom: 20px;
    }
    
    .btn:hover {
        color: #666;
        border-color: #ccc;
        background-color: #FFFFFF;
    }
</style>
